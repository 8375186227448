<template>
  <div>
    <b-list-group v-if="materials.length > 0">
      <b-list-group-item
        v-for="(item, index) in content"
        :key="'materials' + index"
      >
        <b-row>
          <b-col>
            {{ material(item.id) }}
          </b-col>
          <b-col sm="auto">
            <b-button
              v-t="'pages.materials.delete'"
              @click="removeMaterial(index)"
            />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <multiselect
      :placeholder="$t('pages.materials.select')"
      :options="materials.map(i => i.id)"
      :custom-label="k => materials.find(x => x.id == k).material"
      :preserve-search="false"
      :show-labels="false"
      @select="addMaterial"
    />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "MaterialsSelector",
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      materials: []
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    addMaterial(element) {
      this.content.push({ id: element });
      this.$emit("change");
    },
    removeMaterial(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    },
    material(id) {
      return this.materials.find(element => element.id == id).material;
    }
  },
  apollo: {
    materials() {
      return {
        query: gql`
          query materials {
            materials {
              id
              material
            }
          }
        `,
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }
          var _this = this;

          this.materials = result.data.materials.sort((a, b) =>
            a.material.localeCompare(b.material, "sv")
          );
        }
      };
    }
  }
};
</script>
